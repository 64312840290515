<template>
  <BoardView hasBoardTable :pagination="pagination" title="Mokėjimai" :total="total">
    <BtnMulti slot="button">
      <Btn
        :icon="null"
        :isLoading="isExporting"
        @click="createSheetExport({ type: 'payment' })">
        <Icon name="file" /> Eksportuoti
      </Btn>
      <template slot="secondary">
        <Btn
          :isLoading="isExporting"
          @click="createSheetExport({ type: 'payment' })">
          <Icon name="file" /> Visi mokėjimai
        </Btn>
        <Btn
          :isLoading="isExporting"
          @click="createSheetExport({ type: 'paymentMaintenance' })">
          <Icon name="file" /> Priežiūros mokesčiai
        </Btn>
        <Btn
          :isLoading="isExporting"
          @click="createSheetExport({ type: 'paymentUnpaid' })">
          <Icon name="file" /> Skolos
        </Btn>
      </template>
    </BtnMulti>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <div>
        <BulkMessages
          :records="results"
          :recordsSelectedIds.sync="recordsSelectedIds"
          :recordsSelectedUserIds.sync="recordsSelectedUserIds" />
        <CardPayment
          v-for="payment in results"
          :key="payment.id"
          :isChecked="recordsSelectedIds.includes(payment.id)"
          :payment="payment"
          @update:isChecked="selectRecord($event, payment)" />
      </div>
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import BtnMulti from 'components/BtnMulti'
import BulkMessages from './components/admin/BulkMessages'
import CardPayment from './components/admin/CardPayment'
import SheetExport from './mixins/SheetExport'
import Table from './mixins/Table'
import { actions } from 'views/utils'
import { omit } from 'rambda'
import { pluckIds } from 'views/utils/collections'

export default {
  mixins: [SheetExport, Table],
  components: { CardPayment, BtnMulti, BulkMessages },
  data() {
    return {
      paginationOptions: {
        name: 'payment',
        service: 'payment',
        searchIn: [
          'invoiceNo',
          'user.firstName',
          'user.lastName',
        ],
        filters: [
          {
            label: 'Vardas',
            append: ['user.firstName', '$ilike'],
          },
          {
            label: 'Pavardė',
            append: ['user.lastName', '$ilike'],
          },
          {
            label: 'Kliento ID',
            type: 'number',
            append: 'userId',
          },
          {
            label: 'Projektas',
            type: 'select',
            options: () => this.$store.getters.projectOptions,
            append: 'projectId',
          },
          {
            label: 'Sąskaitos numeris',
            append: ['invoiceNo', '$ilike'],
          },
          {
            label: 'Payera užsakymo nr.',
            type: 'number',
            append: 'id',
          },
          {
            label: 'Būsena',
            type: 'select',
            options: this.$store.getters.paymentStatusOptions,
            append: (value) => {
              if (!value) return

              const valueNotNull = {
                [value]: { $ne: null },
              }

              if (value === 'refundedAt') return valueNotNull

              if (value === 'paidAt') {
                return {
                  ...valueNotNull,
                  refundedAt: null,
                }
              }

              const statusTimestamps = {
                acceptedAt: null,
                cancelledAt: null,
                failedAt: null,
                paidAt: null,
                refundedAt: null,
              }

              if (value === 'failedAt') {
                return {
                  ...omit(['acceptedAt'], statusTimestamps),
                  ...valueNotNull,
                }
              }

              return {
                ...statusTimestamps,
                ...valueNotNull,
              }
            },
          },
          {
            label: 'Tipas',
            type: 'select',
            options: this.$store.getters.paymentTypeOptions,
            append: 'type',
          },
          {
            label: 'KW kiekis, nuo',
            type: 'number',
            append: ['reservation.capacity', '$gte'],
          },
          {
            label: 'KW kiekis, iki',
            type: 'number',
            append: ['reservation.capacity', '$lte'],
          },
          {
            label: 'Suma, nuo',
            type: 'number',
            append: ['amountTotal', '$gte'],
          },
          {
            label: 'Suma, iki',
            type: 'number',
            append: ['amountTotal', '$lte'],
          },
          {
            label: 'Data, nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Data, iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_PROJECT',
    'FETCH_RESERVATION',
    'FETCH_USER',
  ),
  watch: {
    async results(payments) {
      if (!payments.length) return

      const [reservationIds, projectIds, userIds] = pluckIds([
        'reservationId',
        'projectId',
        'userId',
      ], payments)

      await this.LOAD(() => Promise.all([
        this.FETCH_PROJECT(projectIds),
        this.FETCH_RESERVATION(reservationIds),
        this.FETCH_USER(userIds),
      ]))
    },
  },
}
</script>
