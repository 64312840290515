<template>
  <BoardView hasBoardTable :pagination="pagination" title="Užsakymai" :total="total">
    <Btn slot="button" class="success icon-side-left" :isLoading="isExporting" @click="createSheetExport">
      <Icon name="file" /> Eksportuoti
    </Btn>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <div>
        <!-- <ReservationQueryStats
          :reservationQuery="fetchQuery" /> -->
        <BulkMessages
          :records="results"
          :recordsSelectedIds.sync="recordsSelectedIds"
          :recordsSelectedUserIds.sync="recordsSelectedUserIds" />
        <CardReservation
          v-for="reservation in results"
          :key="reservation.id"
          :isChecked="recordsSelectedIds.includes(reservation.id)"
          :reservation="reservation"
          @update:isChecked="selectRecord($event, reservation)" />
      </div>
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import BulkMessages from './components/admin/BulkMessages'
import CardReservation from './components/admin/CardReservation'
// import ReservationQueryStats from './components/admin/ReservationQueryStats'
import SheetExport from './mixins/SheetExport'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [SheetExport, Table],
  components: {
    CardReservation,
    BulkMessages,
    // ReservationQueryStats,
  },
  data() {
    return {
      paginationOptions: {
        name: 'adminReservation',
        service: 'reservation',
        searchIn: [
          'user.companyName',
          'user.firstName',
          'user.lastName',
        ],
        filters: [
          {
            label: 'Būsena',
            type: 'select',
            options: [
              [1, 'Apmokėtas'],
              [2, 'Neapmokėtas'],
              [3, 'Atšauktas'],
            ],
            append: (value) => {
              if (value === 1) {
                return {
                  hasPendingPayments: false,
                  isCancelled: false,
                }
              } else if (value === 2) {
                return {
                  hasPendingPayments: true,
                  isCancelled: false,
                }
              } else if (value === 3) {
                return {
                  isCancelled: true,
                }
              }
            },
          },
          {
            label: 'Projektas',
            type: 'select',
            options: () => this.$store.getters.projectOptions,
            append: 'projectId',
          },
          {
            label: 'Nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
          {
            label: 'KW nuo',
            type: 'number',
            append: ['capacity', '$gte'],
          },
          {
            label: 'KW iki',
            type: 'number',
            append: ['capacity', '$lte'],
          },
          {
            label: 'Kliento ID',
            type: 'number',
            append: 'userId',
          },
          {
            label: 'Vardas',
            append: ['user.firstName', '$ilike'],
          },
          {
            label: 'Pavardė',
            append: ['user.lastName', '$ilike'],
          },
          {
            label: 'Promo kodas',
            append: ['promoCode.code', '$ilike'],
          },
          {
            label: 'Sutarties numeris',
            append: ['contract.contractNo', '$ilike'],
          },
          {
            label: 'Sutarties tipas',
            type: 'select',
            options: this.$store.getters.contractTypesOptions,
            append: 'contract.type',
          },
          {
            label: 'Užsakymo suma, nuo',
            type: 'number',
            append: ['priceTotal', '$gte'],
          },
          {
            label: 'Užsakymo suma, iki',
            type: 'number',
            append: ['priceTotal', '$lte'],
          },
          {
            label: 'Newsletter',
            type: 'select',
            options: [
              [true, 'Sutiko'],
              [false, 'Nesutiko'],
            ],
            append: (value) => {
              if (value === undefined) return {}

              return {
                'user.hasSubscribed': value,
              }
            },
          },
        ],
      },
    }
  },
  methods: actions(
    'LOAD_RESERVATIONS_CHILDREN',
    'LOAD_STEP_TREE',
    'FETCH_APVA_STAGE',
  ),
  watch: {
    results: 'LOAD_RESERVATIONS_CHILDREN',
  },
  async beforeMount() {
    await this.LOAD_STEP_TREE()
    await this.FETCH_APVA_STAGE()
  },
}
</script>
