<template>
  <div class="DatePicker Field-cont">
    <label v-if="label" :for="inputId">
      {{ label }}
      <span v-if="required" class="required">
        *
      </span>
    </label>
    <div class="Field">
      <Datetime
        :id="inputId"
        :auto="true"
        :format="hasTime ? 'MMM dd, t' : 'yyyy MMM dd'"
        :minuteStep="15"
        :phrases="{ ok: 'Nustatyti', cancel: 'Atšaukti' }"
        :type="hasTime ? 'datetime' : 'date'"
        :use12Hour="false"
        :value="dateString"
        :weekStart="1"
        @input="setDate" />
    </div>
  </div>
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css'
import { Datetime } from 'vue-datetime'
import { Settings } from 'luxon'
import { randomElementId } from '../../utils'

Settings.defaultLocale = 'lt'

export default {
  components: { Datetime },
  props: {
    hasTime: Boolean,
    label: String,
    required: Boolean,
    value: Date,
  },
  data() {
    return {
      inputId: randomElementId(),
    }
  },
  computed: {
    dateString() {
      if (!this.value) return ''

      return this.value.toISOString()
    },
  },
  methods: {
    setDate(dateString) {
      const emittedDate = dateString
        ? new Date(dateString)
        : null

      if (emittedDate) {
        this.$emit('update:value', emittedDate)
      }
    },
  },
}
</script>

<style lang="scss">
.DatePicker {
  .vdatetime-input {
    background: $white;
    border-color: $border-color;
    cursor: pointer;
    display: block;
    line-height: 1.5rem;
    text-align: center;
    width: 100%;
  }
}

// .vdatetime {
//   &.lg {
//     .vdatetime-input {
//       line-height: 2rem;
//     }
//   }
// }
</style>
